
































import Vue from "vue";
import Footer from "@/components/navigation-footer.vue";
export default Vue.extend({
  data() {
    return {
      sentences: this.$store.state.prioritized,
    };
  },
  computed: {
    reminders: function () {
      return this.$store.state.reminders;
    },
  },
  components: {
    Footer,
  },

  mounted: function () {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
  },
  methods: {
    ToggleReminder: function (index: string) {
     this.$store.dispatch("ToggleReminder", {index:index})
    },
  },
});
